import React, { useContext, useRef, useCallback } from 'react';
import { AuthContext } from 'context/AuthContext';
import { toPng } from 'html-to-image';
import DownPay from "assets/down.png"

import "./index.sass"

const AssetsPage = () => {
  const auth = useContext(AuthContext);
  const ref = useRef<HTMLDivElement>(null)

  const onButtonClick = useCallback(() => {
    if (ref.current === null) {
      return
    }

    toPng(ref.current, {
      cacheBust: true,
      skipFonts: true,
      width: ref.current.scrollWidth * 2.8,
      height: ref.current.scrollHeight * 1,
      style: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }
    })
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = 'promotional-material.png'
        link.href = dataUrl
        link.click()
      })
      .catch((err) => {
        console.log(err)
      })
  }, [ref])

  return (
    <div className='customer-assets-container'>
      <h5>Your website</h5>
      <p><a target="_blank" href={`https://customer.postal.com.au/apps/${auth?.user?._id}`}>https://customer.postal.com.au/apps/{auth?.user._id}</a></p>
      <div ref={ref} className='promotional-container'>
        <h6>{!!auth?.user?.businessName ? auth?.user?.businessName : "Business name"}</h6>
        <h2>Have you downloaded our app?</h2>
        <div className='cornerDiv'>
          <img src={`https://api.qrserver.com/v1/create-qr-code/?size=220x220&data=https://customer.postal.com.au/apps/${auth?.user._id}`} alt="" />
          <div className="corner top-left"></div>
          <div className="corner top-right"></div>
          <div className="corner bottom-left"></div>
          <div className="corner bottom-right"></div>
        </div>
        <p>Postal Apps</p>
      </div>

      <button className='primary button  medium-btn' onClick={onButtonClick} style={{ margin: 'auto' }}>
        <img src={DownPay} alt="imgcardImg" className='imgCards' style={{ width: '23px', marginRight: '8px' }} />
        DOWNLOAD
      </button>
    </div>
  )
}

export default AssetsPage;