import { useCallback, useEffect, useState, useRef, useContext } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import client from 'feathers-client'
import { Formik, FormikHelpers } from 'formik'

import TextSeparator from 'components/TextSeparator/TextSeparator'
import Button from 'components/forms/Button/Button'
import SignInGoogle from 'components/forms/Button/SignInGoogle/SignInGoogle'
import TextField from 'components/forms/TextField/TextField'


import './login.sass'
import { AuthContext } from 'context/AuthContext'

const eye = 
<svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.19999 3.6C8.56347 3.6 7.95302 3.85286 7.50294 4.30294C7.05285 4.75303 6.79999 5.36348 6.79999 6C6.79999 6.63652 7.05285 7.24697 7.50294 7.69706C7.95302 8.14714 8.56347 8.4 9.19999 8.4C9.83651 8.4 10.447 8.14714 10.8971 7.69706C11.3471 7.24697 11.6 6.63652 11.6 6C11.6 5.36348 11.3471 4.75303 10.8971 4.30294C10.447 3.85286 9.83651 3.6 9.19999 3.6ZM9.19999 10C8.13913 10 7.12171 9.57857 6.37157 8.82843C5.62142 8.07828 5.19999 7.06087 5.19999 6C5.19999 4.93913 5.62142 3.92172 6.37157 3.17157C7.12171 2.42143 8.13913 2 9.19999 2C10.2609 2 11.2783 2.42143 12.0284 3.17157C12.7786 3.92172 13.2 4.93913 13.2 6C13.2 7.06087 12.7786 8.07828 12.0284 8.82843C11.2783 9.57857 10.2609 10 9.19999 10ZM9.19999 0C5.19999 0 1.78399 2.488 0.399994 6C1.78399 9.512 5.19999 12 9.19999 12C13.2 12 16.616 9.512 18 6C16.616 2.488 13.2 0 9.19999 0Z" fill="#929292"/>
</svg>;

const eyeclose = <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg" id="eye" x="0" y="0" version="1.1" viewBox="0 0 20 20">
<path d="M18.521 1.478a1 1 0 0 0-1.414 0L1.48 17.107a1 1 0 1 0 1.414 1.414L18.52 2.892a1 1 0 0 0 0-1.414zM3.108 13.498l2.56-2.56A4.18 4.18 0 0 1 5.555 10c0-2.379 1.99-4.309 4.445-4.309.286 0 .564.032.835.082l1.203-1.202A12.645 12.645 0 0 0 10 4.401C3.44 4.4 0 9.231 0 10c0 .423 1.057 2.09 3.108 3.497zm13.787-6.993l-2.562 2.56c.069.302.111.613.111.935 0 2.379-1.989 4.307-4.444 4.307-.284 0-.56-.032-.829-.081l-1.204 1.203c.642.104 1.316.17 2.033.17 6.56 0 10-4.833 10-5.599 0-.424-1.056-2.09-3.105-3.495z" fill="#929292"/>
</svg>;

const google = 
<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.66391 0.723606C5.46577 1.48616 3.5701 2.93352 2.25533 4.85308C0.940572 6.77264 0.276022 9.06323 0.359297 11.3884C0.442572 13.7136 1.26928 15.9507 2.718 17.7713C4.16671 19.5919 6.16107 20.8999 8.40813 21.5033C10.2299 21.9734 12.1385 21.994 13.97 21.5634C15.6291 21.1908 17.1631 20.3936 18.4216 19.25C19.7314 18.0234 20.6821 16.463 21.1716 14.7366C21.7036 12.8591 21.7982 10.8847 21.4483 8.96501H11.2183V13.2086H17.1428C17.0244 13.8854 16.7707 14.5314 16.3968 15.1079C16.0229 15.6844 15.5366 16.1795 14.9669 16.5636C14.2434 17.0422 13.4279 17.3642 12.5727 17.5089C11.7149 17.6684 10.8351 17.6684 9.97735 17.5089C9.10799 17.3292 8.28558 16.9704 7.56251 16.4553C6.40089 15.6331 5.52867 14.4649 5.07032 13.1175C4.60422 11.7449 4.60422 10.2568 5.07032 8.88423C5.39658 7.9221 5.93594 7.04609 6.64813 6.32157C7.46315 5.47723 8.49498 4.87369 9.63043 4.57717C10.7659 4.28065 11.9611 4.30261 13.0848 4.64064C13.9627 4.91012 14.7655 5.38097 15.4292 6.01564C16.0972 5.35105 16.7641 4.68475 17.4299 4.01673C17.7736 3.65751 18.1483 3.31548 18.4869 2.94767C17.4738 2.00489 16.2846 1.2713 14.9875 0.788918C12.6254 -0.0687555 10.0409 -0.0918046 7.66391 0.723606Z" fill="white"/>
<path d="M7.6639 0.723587C10.0407 -0.092378 12.6252 -0.0699356 14.9875 0.78718C16.2848 1.27284 17.4735 2.00996 18.4851 2.95624C18.1414 3.32406 17.7787 3.66781 17.4281 4.02531C16.7612 4.69103 16.0949 5.35447 15.4292 6.01562C14.7655 5.38095 13.9627 4.9101 13.0848 4.64062C11.9614 4.30141 10.7663 4.27818 9.63052 4.57349C8.49478 4.86879 7.46231 5.47122 6.6464 6.31468C5.9342 7.03919 5.39485 7.91521 5.06858 8.87734L1.50562 6.11874C2.78094 3.58971 4.98909 1.65519 7.6639 0.723587Z" fill="#E33629"/>
<path d="M0.560319 8.85156C0.751824 7.90245 1.06976 6.98332 1.50563 6.11874L5.0686 8.88421C4.6025 10.2568 4.6025 11.7449 5.0686 13.1175C3.88152 14.0342 2.69386 14.9554 1.50563 15.8812C0.414484 13.7093 0.0817028 11.2346 0.560319 8.85156Z" fill="#F8BD00"/>
<path d="M11.2183 8.96329H21.4483C21.7982 10.883 21.7036 12.8574 21.1716 14.7349C20.6821 16.4613 19.7314 18.0217 18.4216 19.2483C17.2717 18.3511 16.1167 17.4608 14.9669 16.5636C15.537 16.1791 16.0235 15.6834 16.3974 15.1063C16.7713 14.5292 17.0249 13.8826 17.1428 13.2052H11.2183C11.2166 11.7924 11.2183 10.3778 11.2183 8.96329Z" fill="#587DBD"/>
<path d="M1.50391 15.8812C2.69214 14.9646 3.87979 14.0433 5.06687 13.1175C5.52614 14.4653 6.3996 15.6336 7.5625 16.4553C8.28783 16.9679 9.11201 17.3238 9.9825 17.5003C10.8403 17.6598 11.7201 17.6598 12.5778 17.5003C13.4331 17.3555 14.2486 17.0335 14.972 16.555C16.1219 17.4522 17.2769 18.3425 18.4267 19.2397C17.1684 20.3839 15.6345 21.1817 13.9752 21.5548C12.1437 21.9854 10.235 21.9647 8.41328 21.4947C6.97246 21.11 5.62663 20.4318 4.46016 19.5026C3.22551 18.5224 2.21711 17.2871 1.50391 15.8812Z" fill="#319F43"/>
</svg>;

interface ChildMethods {
  focusInput: () => void;
  // Define additional methods if needed
}
const Login = () => {
  const auth = useContext(AuthContext)
  const navigate = useNavigate();
  const [formInitialEmail, setInitialEmail] = useState("")
  const location = useLocation();
  const passwordRef = useRef<ChildMethods>(null);
  const queryParams: any = new URLSearchParams(location.search);

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const submitLogin = useCallback(
    async (
      values: { email: string; password: string,keepLoggedIn: boolean  },
      actions: FormikHelpers<{ email: string; password: string,keepLoggedIn: boolean  }>
    ) => {
      try {
        // Authenticate with the local email/password strategy
        const user = await client.authenticate(
          {
            strategy: 'local',
            email: values.email,
            password: values.password,
            keepLoggedIn: values.keepLoggedIn
          },
          {
            keepLoggedIn: values.keepLoggedIn
          } as any
        );
        
        if (user?.user?.permissions?.includes("temp") || user?.user?.permissions?.includes("customer")) {
          navigate('/customer')
        }

        if (user?.user?.permissions?.includes("user") || user?.user?.permissions?.includes("administrator")) {
          navigate('/jobs')
        }


      } catch (error: any) {
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: false
        })
      }
      actions.setSubmitting(false)
    },
    [navigate]
  )

  useEffect(() => {
    if (queryParams.get("email") !== null) {
      setInitialEmail(queryParams.get("email"))
      if (passwordRef.current) {
        passwordRef.current.focusInput(); // Access the focusInput method
      }
    }
  }, [location])

  useEffect(() => {
    if (auth !== null && auth.authentication && auth.user) {
      if (auth?.user?.permissions?.includes("temp") || auth?.user?.permissions?.includes("customer")) {
        navigate('/customer')
      } else {
        navigate('/jobs')
      }
    }
  }, [auth])

  return (
    <div className="login-main">
      {/* <h3>Welcome back</h3> */}
      <h1>Login</h1>
      {/* <p>Login with email and password </p> */}
      <Formik
        initialValues={{ email: formInitialEmail, password: '', keepLoggedIn: false}}
        enableReinitialize
        validate={(values) => {
          const errors: any = {}
          if (!values.email) {
            errors.email = 'Required'
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address'
          }

          if (!values.password) {
            errors.password = 'Required'
          }
          return errors
        }}
        onSubmit={submitLogin}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              label="Email address"
              id="email-input"
              placeholder="Enter your email address"
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              errors={errors}
              variant="outlined" 
              
           
            />
            <div style={{ position: "relative" , marginTop:'20px' }}>
              {/* <div className='forgot-pass'>
                <Link to="/login/recovery">Forgot password?</Link>
              </div> */}
              <TextField
                label="Password"
                id="password-input"
                placeholder="Enter your password"
                type={showPassword ? 'text' : 'password'}
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                errors={errors}
                ref={passwordRef}
                capText={
                  <span onClick={togglePasswordVisibility} style={{ cursor: 'pointer', marginLeft: '8px' }}>
                    {showPassword ? eyeclose : eye}
                  </span>
                }
               
              />
            </div>
            {/* <label className="cr-wrapper">
              <input type="checkbox" />
              <span>Keep me logged in</span>
            </label> */}
            

          {/* <label className="cr-wrapper">
          { <TextField 
              type="checkbox" 
              name="keepLoggedIn" 
              checked={values.keepLoggedIn} 
              onChange={handleChange} 
              aria-label="Keep me logged in" // for accessibility
              variant="outlined" 
            /> }
            <span>Keep me logged in</span>
          </label> */}
          <Link to="/login/recovery">Forgot password?</Link>
            <Button
              type="submit"
              fullWidth
              disabled={isSubmitting}
              style={{
                marginTop: '10px',
              }}
            >
              Log in
            </Button>
          </form>
        )}
      </Formik>
     
      <Button
        type="submit"
        fullWidth
        className='googlebtn'
        >
          {google}
          Sign in with Google
        <SignInGoogle />
      </Button>
      
      
      <p className='sinupLabel'>Don&rsquo;t have an account? <Link to='/signup' style={{}}>Sign up</Link></p>

      {/* <Button 
        type="button"
        theme="secondary"
        fullWidth
        style={{
          marginTop: '24px',
        }}
      >
      
      </Button> */}
      {/* <h5 className='' style={{ marginTop: '20px' }}>@Postal Apps </h5> */}

    </div>
  )
}
export default Login