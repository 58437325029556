import { useContext, useState, useEffect, useCallback } from 'react'
import NoData from 'components/NoData/NoData'
import { AuthContext } from 'context/AuthContext'
import client from 'feathers-client'
import { SERVICES, URL_PATH } from 'utils/constants'
import jobsIcon from 'assets/jobs.svg'
import { toast } from 'react-toastify'
import UserItem from './UserItem'
import { Select } from 'antd'
import Button from 'components/forms/Button/Button'
import sortAscIcon from 'assets/sort_asc.svg'
import sortDescIcon from 'assets/sort_desc.svg'
import { LINKS } from 'routes/constants'
import { Link, useLocation } from 'react-router-dom'
import PageHeader from 'components/Header/PageHeader/PageHeader'
import axios, { AxiosRequestConfig } from 'axios';
import EmailSent from 'components/ActionModal/components/EmailSent'


const CustomerUsers = () => {
    const [userData, setUserData] = useState([])
    const auth = useContext(AuthContext)
    const [activeTab, setActiveTab]= useState();
    // console.log(auth)
    const isSuperAdmin = auth?.user?.permissions?.includes("administrator");
    const [sortOption, setSortOption] = useState('invited');
    const [resendEmail, setResendEmail] = useState({
      status:false,
      email: ""
    })
    const [sort, setSort] = useState<any>(1)
    const [template, setTemplate] = useState<any>({})
    const Option = Select.Option;
    const siteRootUrl: string = window.location.origin;


    useEffect(() => {
        fetchDataisTempUser();
    }, [auth]);

    useEffect(()=>{
      const fetchTemplateTimer = setTimeout(async()=>{
        const configE: AxiosRequestConfig = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${URL_PATH.CLIENT1}GetEmailTemplate`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            "type": "existing_customer_access"
          }),
        };
      
  
        try{
          const templateResult = await axios.request(configE);
          if(templateResult.data.status === 200){
            setTemplate(templateResult.data.template.data[0])
          }else{
            console.log("Template not found !!!")
          }
        }
        catch(err:any){
          console.log("Something went wrong!!!", err.message)
        }
      },200)
  
      return () => clearTimeout(fetchTemplateTimer)
    },[auth])

    const fetchDataisTempUser = async () => {
        if (auth && isSuperAdmin) { 
            try {
                const dataUser = await client.service(SERVICES.USERS).find({
                    query: {
                        isTempUser: { $in: [true, false] },
                        $sort: {
                            invited: -1,
                        },
                    }
                });

                dataUser && setUserData(dataUser?.data);
            } catch (error: any) {
                toast.error(error.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                })
            }
        } else {
            console.log("auth", auth);
        }
    }

    const handleDeleteUser = useCallback(async (id: any) => {
        // console.log("clicked", auth, id)
        if (auth) {
          const headers = {
            Authorization: `Bearer ${auth.accessToken}`,
          }
          await client.service(SERVICES.USERS).remove(id, { headers: headers })
          toast.success('User deleted successfully', {
            position: toast.POSITION.BOTTOM_CENTER,
          })
          fetchDataisTempUser()
        }
      }, [auth])
  
    const onClickSortStaff = useCallback(async (option: any, param: number) => {
        // const obj: { [key: string]: any } = {};
    
        try{
          const users = await client.service(SERVICES.USERS).find({
            query: {
              // search: 'af',
              isTempUser: { $in: [true, false] },
              $sort: {
                [option]: param,
              },
            },
          })
          
          if (Array.isArray(users?.data)) {
            setUserData(users?.data);
          }
        }catch(err:any){
          console.log(option, param, {err})
        }
    }, [auth])

    const renderSort = () => {
        return (
          <>
            {userData && userData?.length > 0 && 
              <>
              <div className="sort-title">Sort by</div>
              <Select
                defaultValue="invited"
                style={{ width: 100 }}
                onChange={(e) => handleSortOption(e)}
                className='custom-dropdown-main'
                popupClassName='custom-dropdown'
              >
                <Option value="name">Name</Option>
                <Option value="email">Email</Option>
                {/* <Option value="permissions">Role</Option> */}
                <Option value="invited">Date</Option>
              </Select>
              <Button
                theme="transparent"
                className="close-button"
                onClick={() => {
                  setSort(sort === 1 ? -1 : 1)
                  // if (onClickSortStaff) {
                  onClickSortStaff(sortOption, sort)
                  // }
                }}
              >
                <img
                  src={sort === 1 ? sortAscIcon : sortDescIcon}
                  style={{ height: '24px', width: '24px' }}
                  alt="close"
                />
              </Button>
              {/* <Link to={LINKS.STAFF_INVITE}>
                <Button>Add Staff</Button>
              </Link> */}
              </>
            }
            </>
        )
    }

    const handleSortOption = (value: string) => {
        setSortOption(value)
    }

    const handleResendEmail = useCallback(
      async (staff:any) => {
        let INVITE: any;
        let customer_name = staff.name
        let customerFirstName = customer_name?.split(" ")?.shift();
        // console.log(template)
        INVITE = template?.body?.replaceAll("[Customer Name]", customerFirstName || customer_name)?.replaceAll("[Link]", `${siteRootUrl}/login?email=${staff.email}`)?.replaceAll("[Email]", staff.email);

        try{
          const emailObj = {
            to: staff.email,
            subject: template.subject,
            text: INVITE,
          }
          const email = await client.service(SERVICES.SENDEMAIL).create(emailObj)
          toast.success('Email sent successfully', {
            position: toast.POSITION.BOTTOM_CENTER,
          })
          setResendEmail({
            status:true,
            email:staff.email
          })
        }catch(err:any){
          console.log(err?.message)
          toast.success('Something went wrong!!!', {
            position: toast.POSITION.BOTTOM_CENTER,
          })
        }
        
        // console.log(INVITE)
      },[auth, template]
    )

    if(resendEmail.status){
      return (
        <EmailSent email={resendEmail.email} />
      )
    }

    return (
        <div className="users">
            <PageHeader
                heading={'Customers'}
                // showBack={isPendingPage}
                rightSlot={renderSort()}
            />
            {/* <div className="jobs-tabs-bar">
              <button 
                className={`primary button pri_btn ${location.pathname === link.to  ? "primary_active" :""}`}
                onClick={()=> setActiveTab()}
              >
            
              </button>
            </div> */}
            {
                isSuperAdmin ?
                    userData && userData?.length > 0 ?
                        <div className="customer-content test customers_">
                            
                            <div className="list">
                                {
                                    userData.map((user:any, index) => (
                                        <UserItem
                                            key={index}
                                            staff={user}
                                            handleDeleteUser={()=> handleDeleteUser(user._id)}
                                            handleResendEmail={handleResendEmail}
                                            template={template}
                                            showAdded
                                        />
                                    ))
                                }
                            </div>
                        </div>
                        : (
                            <NoData
                                icon={jobsIcon}
                                title="Not Found!"
                            />
                        )
                    : (
                        <NoData
                            icon={jobsIcon}
                            title="You do not have the correct permissions."
                        />
                    )
            }
        </div >

    )
}


export default CustomerUsers