import { HTMLAttributes } from 'react'

import './index.sass'

interface INoDataProps extends HTMLAttributes<HTMLDivElement> {
  icon: any
  title: string
  description?: string
  cta?: any
}

const NoJobData = ({
  icon,
  title,
  description,
  cta = null,
  ...props
}: INoDataProps) => {
  return (
    <div className="nojobdata" {...props}>
      {icon}
      <h5>{title}</h5>
      <p>{description}</p>
      {cta}
    </div>
  )
}

export default NoJobData
