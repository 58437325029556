export const LINKS = {
  LOGIN: '/login',
  LOGIN_RECOVERY: '/login/recovery',
  PASSWORD_UPDATE: '/login/update',
  LOGOUT: '/logout',
  DASHBOARD: '/',
  EDITPROFILE: "/settings/edit-profile",
  USER_SUBSCRIPTION: "/settings/subscription",

  JOBS: '/jobs',
  JOBS_ITEM: '/jobs/:jobId',
  NEWJOBS: '/jobs/new',
  ACTIVEJOBS: '/jobs/active',
  INACTIVEJOBS: '/jobs/inactive',
  JOBS_CREATE: '/jobs/create',
  JOBS_PENDING: '/jobs/pending',
  JOBS_PENDING_ITEM: '/jobs/pending/:jobId',
  JOBS_DETAILS: '/jobs/details/:jobId',
  JOBS_ADDTASK: '/jobs/AddTask/:jobId',
  // JOBS_EDITASK : '/jobs/EditTask/:editId',

  TASKS: '/jobs/:jobId/tasks',
  TASKS_CREATE: '/jobs/:jobId/tasks/create',
  TASKS_ITEM: '/jobs/:jobId/tasks/:taskId',
  TASKS_EDIT: '/jobs/EditTask/:editId',

  STAFF: '/staff',
  STAFF_INVITE: '/staff/invite',
  STAFF_INVITATION: '/staff/invitation/',
  STAFF_PENDING: '/staff/pending',
  STAFF_PENDING_DETAIL: '/staff/pending/:staffId',
  STAFF_EDIT: '/staff/:staffId/edit',

  TEMPLATES: '/templates',
  TEMPLATES_EMAIL: '/templates/email',
  TEMPLATES_EMAIL_ADD: '/templates/email/add',
  TEMPLATES_EMAIL_VIEW: '/templates/email/:templateId',
  TEMPLATES_APP: '/templates/app',
  TEMPLATES_APP_ADD: '/templates/app/add',
  TEMPLATES_APP_VIEW: '/templates/app/:templateId',
  TEMPLATES_TASK: '/templates/task',
  TEMPLATES_TASK_ADD: '/templates/task/add',
  TEMPLATES_TASK_VIEW: '/templates/task/:templateId',

  SUBSCRIPTION: '/subscription',
  SUBSCRIPTION_ADD: '/subscription/add',
  SUBSCRIPTION_VIEW: '/subscription/:subscriptionId',

  NOTIFICATIONS: '/notifications',

  //CUSTOMER: '/customer',
  CUSTOMER_APPS: '/customer/apps',
  CUSTOMER_APPS_DETAIL: '/customer/apps/:appId',
  CUSTOMER_APPS_TASK: '/customer/apps/:appId/tasks/:taskId',

  CUSTOMER_ONBOARDING: '/customer/onboarding/',
  CUSTOMER_ONBOARDING_INFO: '/customer/onboarding/info',
  CUSTOMER_ONBOARDING_PAYMENT: '/customer/onboarding/payment',
  CUSTOMER_ONBOARDING_UPDATES: '/customer/onboarding/updates',
  CUSTOMER_ONBOARDING_REQUIREMENTS: '/customer/onboarding/requirements',
  CUSTOMER_ONBOARDING_SUCCESS: '/customer/onboarding/success',
  CUSTOMER_predefined: '/predefined',
  CUSTOMER_custom: '/custom',
  HOW_TO_BUILD: '/how-to-build/:id',



  CUSTOMER_TASKS_PENDING: '/customer/tasks/pending',
  CustomerDashboard: '/customer',
  CUSTOMERASSETS: '/assets',
  CUSTOMER_PAYMENTS: '/payments',
  CUSTOMER_PAYMENTS_NEW: '/paymentNew',
  PendingTask: '/pendingtask',

  CUSTOMER_USERS: '/users',
  CUSTOMER_INVENTORY_ADDITEM: '/inventory/addItem',

  START_PAGE: '/signup',
  PAYMENT_SUCCESS: '/payment-success',
  PAYMENT_FAILED: '/payment-failed',
  PAYMENT_AddCard: '/payments/payment-add',
  PAYMENT_EditCard: '/payments/payment-edit/:Id',

}

export const getPendingJobItemLink = (jobId: string) =>
  LINKS.JOBS_PENDING_ITEM.replace(':jobId', jobId)

export const getJobDetailsLink = (jobId: string) =>
  LINKS.JOBS_ITEM.replace(':jobId', jobId)

export const getJobDetailsUrl = (jobId: string) =>
  LINKS.JOBS_DETAILS.replace(':jobId', jobId)

export const getTaskEditDetailsUrl = (editId: string) =>
  LINKS.TASKS_EDIT.replace(':editId', editId)

export const getAddTaskUrl = (jobId: string) =>
  LINKS.JOBS_ADDTASK.replace(':jobId', jobId)

export const getTasksLink = (jobId: string) =>
  LINKS.TASKS.replace(':jobId', jobId)

export const getTaskItemLink = (jobId: string, taskId: string) =>
  LINKS.TASKS_ITEM.replace(':jobId', jobId).replace(':taskId', taskId)

export const getNewTaskLink = (jobId: string) =>
  LINKS.TASKS_CREATE.replace('jobId', jobId)

export const getStaffPendingLink = (staffId: string) =>
  LINKS.STAFF_PENDING_DETAIL.replace(':staffId', staffId)

export const getStaffEditLink = (staffId: string) =>
  LINKS.STAFF_EDIT.replace(':staffId', staffId)

export const getEmailTemplateLink = (templateId: string) =>
  LINKS.TEMPLATES_EMAIL_VIEW.replace(':templateId', templateId)

export const getAppTemplateLink = (templateId: string) =>
  LINKS.TEMPLATES_APP_VIEW.replace(':templateId', templateId)

export const getTaskTemplateLink = (templateId: string) =>
  LINKS.TEMPLATES_TASK_VIEW.replace(':templateId', templateId)

export const getCustomerAppLink = (appId: string) =>
  LINKS.CUSTOMER_APPS_DETAIL.replace(':appId', appId)

export const getCustomerTaskLink = (appId: string, taskId: string) =>
  LINKS.CUSTOMER_APPS_TASK.replace(':appId', appId).replace(':taskId', taskId)

export const getSubscriptionLink = (subscriptionId: string) =>
  LINKS.SUBSCRIPTION_VIEW.replace(':subscriptionId', subscriptionId)

export const getPAYMENT_EditCardLink = (Id: string) =>
  LINKS.PAYMENT_EditCard.replace(':Id', Id)

export const HowToBuild = (Id: string) =>
  LINKS.HOW_TO_BUILD.replace(':Id', Id)


