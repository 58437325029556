import { useCallback, useMemo, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

import { Formik, FormikHelpers } from 'formik'
import { LINKS } from 'routes/constants'
import { TGenericObject } from 'types/base'

import UploadAvatar from 'components/Avatar/UploadAvatar'
import Button from 'components/forms/Button/Button'
import TextArea from 'components/forms/TextArea/TextArea'
import TextField from 'components/forms/TextField/TextField'
import EmailPreview from 'components/EmailPreview/EmailPreview'

export interface IAddAppTemplateFormData {
  icon: string
  type: string
  description: string
  deposit: string
  interim:string
  // monthlySubscription: string
  // yearlyDiscount: string
  finalPayment: string
}

interface IAppTemplateForm {
  handleSubmit: (
    values: IAddAppTemplateFormData,
    actions: FormikHelpers<IAddAppTemplateFormData>,
    attachments: any,
    id?: any
  ) => boolean | TGenericObject
  initialValues?: IAddAppTemplateFormData | null
}

const AppTemplateForm = ({ handleSubmit, initialValues }: IAppTemplateForm) => {
  const [attachments, setAttachments] = useState([])
  const navigate = useNavigate()
  const location = useParams()

  const onSubmit = useCallback(
    async (
      values: IAddAppTemplateFormData,
      actions: FormikHelpers<IAddAppTemplateFormData>
    ) => {
      if (Object.keys(location).length > 0) {
        const data = await handleSubmit(values, actions, attachments, location.templateId)
        if (data) {
          navigate(LINKS.TEMPLATES_APP)
        }
      } else {
        const data = await handleSubmit(values, actions, attachments)
        if (data) {
          navigate(LINKS.TEMPLATES_APP)
        }
      }
    },
    [handleSubmit, navigate, attachments, location]
  )

  const computedInitialValues = useMemo(() => {
    if (initialValues) {
      return {
        icon: initialValues.icon,
        type: initialValues.type,
        description: initialValues.description,
        finalPayment: initialValues?.finalPayment || "",
        deposit: initialValues?.deposit || "",
        interim: initialValues?.interim || "0",
        // monthlySubscription: initialValues.monthlySubscription || "",
        // yearlyDiscount: initialValues.yearlyDiscount || ""
      }
    } else {
      return {
        icon: '',
        type: '',
        description: '',
        finalPayment: "",
        deposit: "",
        interim:"",
        // monthlySubscription: "",
        // yearlyDiscount: ""
      }
    }
  }, [initialValues])


  return (
    <Formik
      initialValues={computedInitialValues}
      enableReinitialize
      validate={(values: IAddAppTemplateFormData) => {
        const errors: any = {}
        if (!values.type) {
          errors.type = 'Required'
        }

        if (!values.deposit) {
          errors.deposit = 'Required'
        }

        if (!values.finalPayment) {
          errors.finalPayment = 'Required'
        }
        // if (!values.monthlySubscription) {
        //   errors.monthlySubscription = 'Required'
        // }
        // if (!values.yearlyDiscount) {
        //   errors.yearlyDiscount = 'Required'
        // }
        // if (+values.yearlyDiscount > 100) {
        //   errors.yearlyDiscount = 'Discount should be less than 100'
        // }

        if (!values.description) {
          errors.description = 'Required'
        }

        return errors
      }}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        const wordCount = values.description
          ? String(values.description).split(' ').length
          : 0
        return (
          <div className="form-container">
            <div className="left">
              <form onSubmit={handleSubmit}>
                <UploadAvatar
                  name="icon"
                  type="file"
                  onChange={handleChange}
                  // handleRemoveImg={handleRemoveImg}
                  setAttachments={setAttachments}
                  attachments={attachments}
                  deafaultImage={values.icon}
                />
                <TextField
                  label="App type title"
                  id="app-type-title-input"
                  placeholder="Type app type title..."
                  type="text"
                  name="type"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.type}
                  errors={errors}
                  required
                />
                <TextField
                  label="Deposit"
                  id="app-deposit-input"
                  placeholder="App deposit"
                  type="number"
                  name="deposit"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.deposit}
                  errors={errors}
                  capText={"USD"}
                  required
                />

                 <TextField
                  label="Interim Payment"
                  id="app-interim-input"
                  placeholder="App Interim"
                  type="number"
                  name="interim"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.interim}
                  errors={errors}
                  capText={"USD"}
                />

                <TextField
                  label="Final payment"
                  id="app-finalPayment-input"
                  placeholder="App final payment"
                  type="number"
                  name="finalPayment"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.finalPayment}
                  errors={errors}
                  capText={"USD"}
                  required
                />
                {/* <TextField
                  label="Subscription (per month)"
                  id="app-monthlysubscription-input"
                  placeholder="App monthly subscription"
                  type="number"
                  name="monthlySubscription"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.monthlySubscription}
                  errors={errors}
                  capText={"USD"}
                  required
                />
                <TextField
                  label="Yearly discount"
                  id="app-yearlyDiscount-input"
                  placeholder="App yearly discount"
                  type="number"
                  name="yearlyDiscount"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.yearlyDiscount}
                  errors={errors}
                  capText={"%"}
                  required
                /> */}
                <TextArea
                  label="Short description"
                  id="app-type-description-input"
                  placeholder="Type app type description..."
                  name="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                  errors={errors}
                  required
                />
                <div className="wordcount">{wordCount}/20 words</div>
                <div className="buttons-container right">
                  <Button
                    className='button medium-btn cancel-btn'
                    type="button"
                    disabled={isSubmitting}
                    theme="secondary"
                    style={{
                      marginRight: '12px',
                    }}
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" className='button medium-btn' disabled={isSubmitting}>
                    Done
                  </Button>
                </div>
              </form>
            </div>
            <div className="right">
              {(() => {
                let previewJSX = `App type title:
${values.type}

Deposit:
$${values.deposit}

Interim Payment:
$${values.interim}

Final payment:
$${values.finalPayment}

Short description:
${values.description}`


                return (
                  <EmailPreview body={previewJSX} />
                )
              })()}
            </div>
          </div>
        )
      }}
    </Formik>
  )
}

export default AppTemplateForm
