import { toast } from 'react-toastify'

import client from 'feathers-client'
import { FormikHelpers } from 'formik'
import { TGenericObject } from 'types/base'
import { SERVICES, URL_PATH } from 'utils/constants'


import axios from 'axios'

export const SubscriptionFetchingSort = async () => {
    try {
        const data = await client.service(SERVICES.SUBSCRIPTION).find();
        return data
    } catch (error: any) {
        toast.error(error.message, {
            position: toast.POSITION.BOTTOM_CENTER,
        })
        return false
    }
}

interface IAddAppTemplateFormData {
    packageTitle: string
    monthlySubscription: string
    yearlyDiscount: string
}

export const submitSubscriptionHandler = async (
    values: IAddAppTemplateFormData,
    actions: FormikHelpers<IAddAppTemplateFormData>,
    extraData?: any
) => {
    const payload: any = {
        packageTitle: values.packageTitle,
        monthlySubscription: values.monthlySubscription,
        yearlyDiscount: +values.yearlyDiscount,
        yearlyDiscountType: extraData.yearlyDiscountType,
        features: extraData.features.filter((feat: any) => feat.value.trim().length),
        admin_id: extraData.auth.user._id
    };
    try {
        // const attachmentsNew = await uploadImagesHandler(attachments)
        const data = await client.service(SERVICES.SUBSCRIPTION).create(payload)
        toast.success('Subscription created successfully', {
            position: toast.POSITION.BOTTOM_CENTER,
        })
        actions.setSubmitting(false)
        return data
    } catch (error: any) {
        toast.error(error.message, {
            position: toast.POSITION.BOTTOM_CENTER,
        })
        actions.setSubmitting(false)
        return false
    }
}

export const updateSubscriptionHandler = async (
    values: IAddAppTemplateFormData,
    actions: FormikHelpers<IAddAppTemplateFormData>,
    extraData: any
) => {
    try {
        const payload: any = {
            packageTitle: values.packageTitle,
            monthlySubscription: values.monthlySubscription,
            yearlyDiscount: +values.yearlyDiscount,
            yearlyDiscountType: extraData.yearlyDiscountType,
            features: extraData.features.filter((feat: any) => !!feat.value.trim()),
            admin_id: extraData.auth.user._id
        };
        const data = await client.service(SERVICES.SUBSCRIPTION).patch(extraData.id, payload)
        toast.success('Subscription updated successfully', {
            position: toast.POSITION.BOTTOM_CENTER,
        })
        actions.setSubmitting(false)
        return data
    } catch (error: any) {
        toast.error(error.message, {
            position: toast.POSITION.BOTTOM_CENTER,
        })
        actions.setSubmitting(false)
        return false
    }
}

export const getSubscriptionHandler = async (subscriptionId: string) => {
    try {
        const data = await client.service(SERVICES.SUBSCRIPTION).get(subscriptionId)
        return data
    } catch (error: any) {
        toast.error(error.message, {
            position: toast.POSITION.BOTTOM_CENTER,
        })
        return false
    }
}

export const deleteSubscriptionHandler = async (templateId: string) => {
    try {
        const data = await client.service(SERVICES.SUBSCRIPTION).remove(templateId)
        toast.success('Subscription deleted successfully', {
            position: toast.POSITION.BOTTOM_CENTER,
        })
        return data
    } catch (error: any) {
        toast.error(error.message, {
            position: toast.POSITION.BOTTOM_CENTER,
        })
        return false
    }
}
