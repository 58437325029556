import React, { useState, useEffect } from 'react'
import { SubscriptionFetchingSort } from 'pages/subscriptions/utils';
import "./Subscription.sass";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/store';
import BaseModal from 'components/ActionModal/BaseModal';
import Card from 'pages/customer/payments/Card';
import { getImageForBrand, getExpiryStatus, checkAuthentication } from 'utils/helpers';
import { useNavigate } from 'react-router-dom';
import { LINKS } from 'routes/constants';
import Loader from 'components/Loader/Loader';
import { URL_PATH } from 'utils/constants';
import axios from 'axios';
import { toast } from 'react-toastify';
import { setUserData } from 'store/slices/userSlice';


const checkIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1ZM17.9101 8.5H16.3543C16.3013 8.5 16.2504 8.52107 16.2129 8.55857L10.6961 14.0746L8.17958 11.5582C8.14207 11.5207 8.0912 11.4996 8.03816 11.4996H6.4827C6.42967 11.4996 6.37881 11.5207 6.34131 11.5582C6.26319 11.6363 6.26316 11.7629 6.34125 11.841C6.42917 11.929 6.5171 12.0169 6.60502 12.1049C6.62887 12.1288 6.65325 12.1532 6.67813 12.1781L6.83305 12.3334C7.15375 12.655 7.53643 13.0404 7.92601 13.4333L8.21862 13.7286C9.12764 14.6461 9.99919 15.5287 10.1333 15.6643L10.1505 15.6816C10.4434 15.9745 10.9183 15.9745 11.2112 15.6816L18.0516 8.84142C18.0891 8.80392 18.1101 8.75304 18.1101 8.7C18.1101 8.58954 18.0206 8.5 17.9101 8.5Z" fill="#00B8D9" />
</svg>;

const crossIcon = <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.4 15L10 11.4L13.6 15L15 13.6L11.4 10L15 6.4L13.6 5L10 8.6L6.4 5L5 6.4L8.6 10L5 13.6L6.4 15ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="#EB5757" />
</svg>
  ;
  
const Subscription = () => {
  const dispatch = useDispatch();
  const [subscriptionsList, setSubsScriptionsList] = useState<any>([])
  const userData = useSelector((state:RootState)=> state.userSlice.userData);
  const [userSubscription, setUserSubscription]= useState<any>(null);
  const [savedCards, setSavedCards] = useState<any>([]);
  const [showPaymentModal, setShowPaymentModal] = useState<any>(false)
  const [selectedSubscription, setSelectedSubscription] = useState<any>({})
  const [selectedPaymentCard, setSelectedPaymentCard] = useState<any>(null)
  const [paymentLoader, setPaymentLoader] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubscriptions = setTimeout(async () => {
      try {
        const subscriptions = await SubscriptionFetchingSort();
        // Handle the subscriptions data as needed
        setSubsScriptionsList(subscriptions.data || [])
        if(!!userData && userData?.user?.subscriptionStatus === "active" &&  !!userData?.user?.subscription_Id && subscriptions?.data?.length > 0){ 
          let userSubs = subscriptions.data.find((subs:any) => subs._id === userData?.user?.subscription_Id);
          if(userSubs){
            setUserSubscription(userSubs);
            setSubsScriptionsList(subscriptions.data.filter((subs:any)=> subs._id !== userSubs._id))
          }
        }
      } catch (error) {
        // Handle any errors that occur during the fetch
        console.error('Error fetching subscriptions:', error);
      }
    }, 100)

    return () => clearTimeout(fetchSubscriptions)
  }, [userData]);

  useEffect(() => {
    if (!!userData?.user) {
      fetchPaymentMethods();
    }
  }, [userData]);


  const renderedCards = () => {
    return (

      <div>
        <div className="cards-list" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '40px' }}>
          {loading && (
            <div className="loader"></div>
          )}
          {savedCards?.length ? savedCards.map((card:any, index:number) => (
            // card
            <div key={index} style={{ width: '180px', height: '180px' }}>
              <Card
                key={index}
                title={card.card.brand === 'paypal' ? card.card.brand : `Ending in ${card.card.last4}`}
                expiryDate={card.card.exp_month + '/' + card.card.exp_year}
                image={getImageForBrand(card.card.brand)}
                showStatus={getExpiryStatus(card.card.exp_month, card.card.exp_year)}
                checkbox={true}
                checked={selectedPaymentCard?.card?.last4 === card.card.last4} // Pass checked prop based on selectedCardIndex
                onClick={() => {
                  setSelectedPaymentCard(card)
                }} // Pass onClick function
              // paymentMethodId={paymentMethodId}
              />
            </div>
          ))
            :
            <p>No cards saved yet.</p>
          }
        </div>
      </div>
    )
  }

  const fetchPaymentMethods = async () => {
    try {
      setLoading(true);
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${URL_PATH.CLIENT1}get-payment-methods`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          user_Id: userData?.user?._id,
          name: userData?.user?.name,
          email: userData?.user?.email
        }),
      };
      const response = await axios.request(config);
      if (response && (response.status === 200 || response.status === 201)) {
        setSavedCards(response?.data?.data);
        setSelectedPaymentCard(response?.data?.data[0] || [])
        console.log('RESPONSE ======== ', response)
      }
    } catch (error) {
      console.error('Error fetching payment methods:', error);
    } finally {
      setLoading(false)
    }
  };

  const paymentModalJSX = (
    <div className='payment-modal-main'>
      <div className=''>
        <img src="./assets/logo-black-text.png" alt="" />
      </div>
      <div className="credit_card_heading" style={{
        marginTop: '20px',
      }}>
        <h4 style=
          {{
            fontWeight: '600',
            color: '#00314D',
            marginBottom: '10px',
            fontSize: '40px',
            lineHeight: '48px',
          }}>Make Payment</h4>
        <p style={{
          marginBottom: '20px',
          color: '#60747E',
          fontSize: '16px',
          lineHeight: '19.2px',
          fontWeight: '400',
        }}
        >Manage your credit cards and payment options.</p>
      </div>
      <div className="payment_method">
        {renderedCards()}
      </div>
      <div
        className='payment_logo'
        style={{
          display: 'flex',
          gap: '0px 10px',
          marginTop: '50px',
        }}
      >
        <img src="assets/cardlogo.png" />
        <img src="assets/visacardlogo.png" />
        <img src="/assets/paypallogo.png" />
        <img src="/assets/discoverlogo.png" />
        <img src="/assets/amricanlogo.png" />
      </div>
    </div>
  )

  const paymentModalActionsJSX = (
    <>
      <button className="cancel_button" style={{ cursor: "pointer" }} onClick={() => setShowPaymentModal(false)}>Cancel</button>

      <button
        disabled={paymentLoader}
        style={{ minWidth: paymentLoader ? 180 : "fit-content" }}
        className="primary button"
        onClick={() => {
          if (!savedCards?.length) {
            navigate(LINKS.CUSTOMER_PAYMENTS)
          } else {
            paymentHandler("MONTHLY")
          }
        }}
      >{savedCards?.length ?
        paymentLoader ? <Loader className='white-color' /> : `Pay Now`
        :
        "Add Card"
        }
      </button>
    </>
  )

  const paymentHandler = async (paymentType: any) => {
    // setPaymentLoader(true)       
    const paymentRequest: any = {
      "user_Id": userData.user._id,
      "subscription_Id": selectedSubscription._id,
      "paymentMethodId": selectedPaymentCard.id,
      "plan": paymentType//MONTHLY//YEARLY
    };


    if (selectedPaymentCard) {
      paymentRequest.paymentMethodId = selectedPaymentCard.id;
    }

    console.log(paymentRequest, selectedSubscription, selectedPaymentCard)
    const configE = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${URL_PATH.CLIENT1}create-payment`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(paymentRequest),
    };

    try {
      // setLoading(true);
      const apiResult = await axios.request(configE);
      console.log("apiResult >>", apiResult);

      const { status, message } = apiResult.data;

      if (status === 400 || status === 500) {
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 6000
        });

        setTimeout(() => {
          navigate(LINKS.PAYMENT_FAILED);
          setShowPaymentModal(false)
        }, 2000);
      } else if (status === 200 || status === 201) {
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 6000
        });

        // if (paymentType === "deposit") {
        //   isJobActiveHandler(selectedJobDataPayment.id);
        // } else {
        //   fullPaymentHandler(selectedJobDataPayment.id);
        // }

        setTimeout(() => {
          
          navigate(LINKS.PAYMENT_SUCCESS);
          window.location.reload();
          setShowPaymentModal(false);
        }, 3000);
      }
      setPaymentLoader(false);
    } catch (err: any) {
      setPaymentLoader(false);
      console.log("Something went wrong!!!", err.message);

      setTimeout(() => {
        navigate(LINKS.PAYMENT_FAILED);
        setShowPaymentModal(false);
      }, 2000);
    } finally {
      // setLoading(false);
    }
  }

  const cancelSubscriptionHandler = async() => {
      try{
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${URL_PATH.CLIENT1}subscription-cancel`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            user_Id: userData.user._id
          }),
        };
        const response = await axios.request(config);
        const userUpdatedData = await checkAuthentication();
        console.log("cancel >>>>", {response, userUpdatedData});
        
        if(response && userUpdatedData){
          if(userUpdatedData.status){
            dispatch(setUserData(userUpdatedData.auth));
          }
          window.location.reload();
        }

      }catch(error:any){

      }
  } 


  console.log("checkAuthentication >>>>",{subscriptionsList, userData, userSubscription});
  
  return (
    <>
      <div className="page-title">
        My subscription
      </div>
      <div className='user-subscription-container'>
        <div className="current-subscription">
            <div className="tab-heading">
              Current Plan
            </div>
            {userData?.user?.subscriptionStatus === "active" && !!userSubscription ? 
              <div className="subs-packages-container">
                <div className="subs-package" >
                <div className="subs-uppper">
                  <h3 className="packages-title">{userSubscription.packageTitle}</h3>
                  <div className="package-price">
                    <span className="currency-sign">$</span>
                    <span className='main-price'>{userSubscription.monthlySubscription}</span>
                    <span className='package-duration'>/month</span>
                  </div>
                  <div className='package-features-list'>
                    {userSubscription.features?.length > 0 && userSubscription.features.map((feature: any, index: number) => (
                      <div className="package-feature-item" key={index}>
                        <div className="item-icon">{feature.checked ? checkIcon : crossIcon}</div>
                        <div className="item-title">{feature.value}</div>
                      </div>
                    ))}
                  </div>
                </div>
                  <button className='subs-btn' onClick={cancelSubscriptionHandler}>Cancel Subscription</button>
                </div>
              </div>
            :
            <>No Active Plan!</>}
        </div>
        <div className="available-subscriptions">
          <div className="tab-heading">
            Available Plans
          </div>
          <div className="subs-packages-container">
            {subscriptionsList?.length > 0 && subscriptionsList.map((subscription: any, index: number) => (
              <div className="subs-package" key={index}>
                <div className="subs-uppper">
                  <h3 className="packages-title">{subscription.packageTitle}</h3>
                  <div className="package-price">
                    <span className="currency-sign">$</span>
                    <span className='main-price'>{subscription.monthlySubscription}</span>
                    <span className='package-duration'>/month</span>
                  </div>
                  <div className='package-features-list'>
                    {subscription.features?.length > 0 && subscription.features.map((feature: any, index: number) => (
                      <div className="package-feature-item" key={index}>
                        <div className="item-icon">{feature.checked ? checkIcon : crossIcon}</div>
                        <div className="item-title">{feature.value}</div>
                      </div>
                    ))}
                  </div>
                </div>
                  <button className='subs-btn' onClick={() => {
                      if(userSubscription === null){
                        setSelectedSubscription(subscription);
                        setShowPaymentModal(true);
                      }else{
                        console.log("Upgrade subs or downgrade")
                      }
                  }}>{
                    userSubscription === null ? 
                      "Subscribe"
                    :
                      subscription.monthlySubscription > userSubscription.monthlySubscription ? `Upgrade to ${subscription.packageTitle}` : `${subscription.packageTitle} Plan`
                  }</button>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <BaseModal
        className='paymentModal'
        isOpen={showPaymentModal}
        children={paymentModalJSX}
        actionButtons={paymentModalActionsJSX}
        handleClose={() => setShowPaymentModal(false)}
      />
    </>
  )
}

export default Subscription