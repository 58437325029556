import { useCallback, useState, useEffect } from 'react'
import Button from 'components/forms/Button/Button';
import TextField from 'components/forms/TextField/TextField';
import { Formik, FormikHelpers } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { URL_PATH,crypto_secret } from 'utils/constants'
import './login.sass';
import axios, { AxiosRequestConfig } from 'axios';
import CryptoJS from 'crypto-js';
import { SERVICES } from 'utils/constants'
import client from 'feathers-client'


const LoginRecovery = () => {

  const navigate = useNavigate();
  const encryptionKey = crypto_secret;
  const [template, setTemplate] = useState<any>({})
  const [showSuccessScreen,  setShowSuccessScreen] = useState(false)
  useEffect(()=>{

    const fetchTemplateTimer = setTimeout(async()=>{
      const configE: AxiosRequestConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${URL_PATH.CLIENT1}GetEmailTemplate`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          "type": "forget_password"
        }),
      };
    

      try{
        const templateResult = await axios.request(configE);
        if(templateResult.data.status === 200){
          setTemplate(templateResult.data.template.data[0])
        }else{
          console.log("Template not found !!!")
        }
      }
      catch(err:any){
        console.log("Something went wrong!!!", err.message)
      }
    },200)

    return () => clearTimeout(fetchTemplateTimer)
  },[])

  const encryptUserId = (userId: string) => {
    const encrypted = CryptoJS.AES.encrypt(userId, encryptionKey).toString();
    return encrypted;
  };

  const submitLogin = useCallback(
    async (
      values: { email: string },
      actions: FormikHelpers<{ email: string }>
    ) => {
      try {

        const config: AxiosRequestConfig = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${URL_PATH.CLIENT1}/password-reset`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: { email: values?.email },
        };
      
        const response = await axios.request(config);
        if(response.data.message){
          toast.error(response.data.message, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }else{
          const encryptedUserId = encryptUserId(response.data.userId);
          const siteRootUrl: string = window.location.origin;
    
          const INVITE = template.body.replaceAll('[Link]', ` ${siteRootUrl}/login/update?token=${encryptedUserId}`);
       
            const emailObj = {
              to: values?.email, // Replace with your email address
              subject: template.subject,
              text: INVITE,
            }
            const email = await client.service(SERVICES.SENDEMAIL).create(emailObj)
            toast.success('Password reset email sent successfully', {
              position: toast.POSITION.BOTTOM_CENTER,
            })
            setShowSuccessScreen(true)
        }

      } catch (error: any) {

        if (error instanceof Error && error.message === 'Invalid login') {
          toast.error('Invalid login', {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        } else {
          toast.error('An unexpected error occurred', {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      }
      actions.setSubmitting(false);
    },
    [navigate, template]
  );

  if(showSuccessScreen){
    return (
      <div className='success-container'>
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="70" height="70" rx="35" fill="#E5F8FB"/>
          <path d="M44.3744 27.4531H42.7361C42.5064 27.4531 42.2885 27.5586 42.1478 27.7391L32.4846 39.9805L27.851 34.1094C27.7809 34.0204 27.6915 33.9484 27.5896 33.8989C27.4878 33.8494 27.376 33.8236 27.2627 33.8234H25.6244C25.4674 33.8234 25.3807 34.0039 25.4767 34.1258L31.8963 42.2586C32.1963 42.6383 32.7728 42.6383 33.0752 42.2586L44.5221 27.7531C44.6182 27.6336 44.5314 27.4531 44.3744 27.4531Z" fill="#00B8D9"/>
        </svg>
        <h3>Success!</h3>
        <p>Password recovery instructions will be sent to your email.</p><br />
        <Link to="/login" className="primary button " style={{width: "100%"}}>
          <span style={{transform: "rotate(180deg)"}}><img src="/assets/Vector.png" /></span>&nbsp;&nbsp;
          Back
        </Link>
      </div>
    )
  }

  return (
    <div className="login-recovery">
      <h3>Forgot password</h3>
      {/* <p>Enter your email</p> */}
      <Formik
        initialValues={{ email: '' }}
        validate={(values) => {
          const errors: any = {};
          if (!values.email) {
            errors.email = 'Required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          }
          return errors;
        }}
        onSubmit={submitLogin}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              label="Email address"
              id="email-input"
              placeholder="Type your email..."
              type="email"
              required
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              errors={errors}
            />
            <Button
              type="submit"
              fullWidth
              disabled={isSubmitting}
              style={{
                marginTop: '8px',
              }}
            >
              Send link
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default LoginRecovery;
