import { HTMLAttributes, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { getSubscriptionLink } from 'routes/constants'
import { DEFAULT_AVATAR } from 'utils/constants'
import SubscriptionPreview from 'components/SubscriptionPreview/SubscriptionPreview'
import DeleteModal from 'components/ActionModal/DeleteModal'
import ModalPreviewAppTemplate from 'components/ActionModal/components/ModalPreviewAppTempate'
import KebabMenu, { IKebabMenuItem } from 'components/KebabMenu/KebabMenu'

import deleteIcon from 'assets/delete.svg'
import editIcon from 'assets/edit.svg'

import { SubscriptionItem } from './Subscriptions'
import { deleteSubscriptionHandler } from './utils'
import './subscriptionStyle.sass'

interface SubscriptionListingItemProps {
    subscription: SubscriptionItem;
    refetch?: () => void,
    setSelectedSubscription?: (subscription:any)=> void
}

const SubscriptionListingItem = ({
    subscription,
    refetch = () => { },
    setSelectedSubscription=() => {}
}: SubscriptionListingItemProps) => {
    const [templateImage, setTemplateImage] = useState(DEFAULT_AVATAR)
    const [deleteOpen, setDeleteOpen] = useState<boolean>(false)
    const navigate = useNavigate()


    // const deleteTemplate = useCallback(async () => {
    //     const data = await deleteSubscriptionHandler(subscription._id)

    //     if (data) {
    //         setDeleteOpen(false)
    //         refetch()
    //     }
    // }, [subscription, refetch])

    const templateActions: IKebabMenuItem[] = [
        {
            label: 'Edit',
            icon: editIcon,
            onClick: () => {
                navigate(getSubscriptionLink(subscription._id))
            },
        },
        // {
        //     label: 'Delete',
        //     icon: deleteIcon,
        //     onClick: () => {
        //         setDeleteOpen(true)
        //     },
        // },
    ]


    return (
        <div className="subscription-card-item" onClick={()=>setSelectedSubscription(subscription)}>
            <div className="subscription-card-content">
                <div className="icon">
                    <img src={templateImage} alt="" />
                </div>
                <div className="subscription-card-details">
                    <div className="title">{subscription.packageTitle}</div>
                    <div className="description">Monthly Price  <span className='currency'>${subscription.monthlySubscription}</span></div>
                </div>
            </div>
            <div className='subscription-card-options'>
                <KebabMenu items={templateActions} />
            </div>
            {/* <DeleteModal
                isOpen={deleteOpen}
                handleClose={() => {
                    setDeleteOpen(false)
                }}
                body={
                    <ModalPreviewAppTemplate
                        icon={templateImage}
                        title={subscription.packageTitle}
                        subtitle={""}
                    />
                }
                handleDelete={deleteTemplate}
                title="Delete subscription"
                subtitle="Do you wish to delete the subscription?"
            /> */}
        </div>
    )
}

interface SubscriptionsProps extends HTMLAttributes<HTMLDivElement> {
    subscriptions: SubscriptionItem[];
    fetchSubscriptions_fn: () => void;
}

const ListSubscriptions = ({
    subscriptions,
    fetchSubscriptions_fn,
    ...props
}: SubscriptionsProps) => {
    const [selectedSubscription, setSelectedSubscription] = useState<any>(subscriptions[0])

    const previewJSX = `
        Subscription package title: test
        Subscription (per month): $12
        Yearly discount: $12%
        Features: test
    `;

    const customOrderById = [
        "671aebab5db4d6db2df97839", // Proton Starter
        "66fbbb39abe8662d01feb279", // Proton Basic
        "66fbbbb3abe8662d01feb27a", // Proton Pro
        "67078363d84afbf6d5af9ac7"  // Proton Pro Plus
    ];
    
    subscriptions && subscriptions.sort((a, b) => customOrderById.indexOf(a._id) - customOrderById.indexOf(b._id));
    

    return (
        <div className='subscription-main'>
            <div className="subscription-left">
                <div className="subscription-cards-list" {...props}>
                    
                    {subscriptions && subscriptions.length > 0 ? (
                        subscriptions.map((subscription) => (
                            
                            <SubscriptionListingItem
                                subscription={subscription}
                                refetch={fetchSubscriptions_fn}
                                key={subscription._id}
                                setSelectedSubscription={setSelectedSubscription}
                            />
                        ))
                    ) : (
                        <div>No subscriptions created yet</div>
                    )}
                </div>
            </div>
            <div className="subscription-right">
                <SubscriptionPreview subscription={selectedSubscription} />
            </div>
        </div>

    );
};

export default ListSubscriptions;
