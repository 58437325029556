import React, { useState, useReducer, Dispatch, useEffect, useCallback } from 'react'
import { onFocusOut, onInputChange, UPDATE_FORM, validateInput } from '../../../components/lib/customer/form/predefined'
import { Input } from 'antd'
import '../customer.sass'
import './index.sass'
import client from 'feathers-client'
import { SERVICES, URL_PATH, generateRandomPassword } from 'utils/constants'
import axios, { AxiosRequestConfig } from 'axios';
import { authReducer } from 'context/AuthContext'
import { toast } from 'react-toastify'

const initialState = {
    appTitle: { value: "", touched: false, hasError: true, error: "" },
    isFormValid: false,
}

const formReducer = (state: any, action: any) => {
    switch (action.type) {
        case UPDATE_FORM:
            const { name, value, hasError, error, touched, isFormValid } = action.data
            return {
                ...state,
                [name]: { ...state[name], value, hasError, error, touched },
                isFormValid,
            }
        default:
            return state
    }
}

const confirmValidity = (formState: any, dispatch: Dispatch<any>) => {
    let isFormValid = true;
    for (let name in formState) {
        const item = formState[name]
        const { value } = item
        const { hasError, error } = validateInput(name, value, formState)
        if (hasError) {
            isFormValid = false
        }
        if (name) {
            dispatch({
                type: UPDATE_FORM,
                data: {
                    name,
                    value,
                    hasError,
                    error,
                    touched: true,
                    isFormValid,
                },
            })
        }
    }
    return isFormValid;
}

const PredefinedPage = () => {
    const [predefinedForm, dispatchForm] = useReducer(formReducer, initialState);
    const [auth, dispatch] = useReducer(authReducer, null)
    const [template, setTemplate] = useState<any>({})
    const [template2, setTemplate2] = useState<any>({})

    const checkAuthentication = useCallback(async () => {
        try {
            const auth = await client.reAuthenticate()
            dispatch({
                type: 'added',
                auth,
            })
        } catch (error) {
            // navigate('/login')
        }
    }, [])
    useEffect(() => {
        checkAuthentication()
    }, [checkAuthentication])

    useEffect(() => {
        const fetchTemplateTimer = setTimeout(async () => {
            const configE: AxiosRequestConfig = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${URL_PATH.CLIENT1}GetEmailTemplate`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    "type": "customer_access"
                }),
            };


            try {
                const templateResult = await axios.request(configE);
                if (templateResult.data.status === 200) {
                    setTemplate(templateResult.data.template.data[0])
                } else {
                    console.log("Template not found !!!")
                }
            }
            catch (err: any) {
                console.log("Something went wrong!!!", err.message)
            }
        }, 200)

        return () => clearTimeout(fetchTemplateTimer)
    }, [])

    useEffect(() => {
        const fetchTemplateTimer = setTimeout(async () => {
            const configE: AxiosRequestConfig = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${URL_PATH.CLIENT1}GetEmailTemplate`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    "type": "existing_customer_access"
                }),
            };


            try {
                const templateResult = await axios.request(configE);
                if (templateResult.data.status === 200) {
                    setTemplate2(templateResult.data.template.data[0])
                } else {
                    console.log("Template not found !!!")
                }
            }
            catch (err: any) {
                console.log("Something went wrong!!!", err.message)
            }
        }, 200)

        return () => clearTimeout(fetchTemplateTimer)
    }, [])


    const onSubmit = async (e: any) => {
        e.preventDefault();
        const formVaild = confirmValidity(predefinedForm, dispatchForm);

        if (formVaild) {

            const payload = {
                appTitle: predefinedForm?.appTitle?.value,
                customerBusinessName: '',
                appIndustry: 'Predefined',
                appDescription: '',
                appImage: '',
                appRequirements: '',
                attachments: [],
                appPrice: '',
                developedFor: '',
                appUpdates: '',
                appTheme: '',
                color: '',
                isSubmitted: true,
                depositReceived: false,
                finalPaymentReceived: false,
                buildingFinished: false,
                isJobActive: false,
                deposit: 0,
                finalPayment: 0,
                monthlySubscription: 0,
            }

            if (auth?.user?.email && auth?.user?.name) {
                const config: AxiosRequestConfig = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${URL_PATH.CLIENT1}JobCreate`,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: JSON.stringify({
                        name: "",
                        customer_name: auth?.user?.name,
                        customer_email: auth?.user?.email,
                        //business_name: values.business_name,
                        invite_email: ``,
                        isSubmitted: false,
                        isJobActive: false
                    }),
                };

                const jobCreate: any = await axios.request(config);
                const id: any = jobCreate.data.data._id

                const config1: AxiosRequestConfig = {
                    method: 'patch',
                    maxBodyLength: Infinity,
                    url: `${URL_PATH.CLIENT1}/customerJobUpdate/${id}`,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: payload,
                };

                const dataUser = await axios.request(config1);
                const cu_email = dataUser?.data?.data;

                // let config_name: AxiosRequestConfig = {
                //     method: 'get',
                //     maxBodyLength: Infinity,
                //     url: `${URL_PATH.CLIENT1}customerjobstatusGet/${id}`,
                // };
                // const dataUserName = await axios.request(config_name);
                // const customer_name = dataUserName?.data?.job?.customerName;
                // const newPassword: string = generateRandomPassword(10);
                // const siteRootUrl: string = window.location.origin;
                // const configE: AxiosRequestConfig = {
                //     method: 'post',
                //     maxBodyLength: Infinity,
                //     url: `${URL_PATH.CLIENT1}/password-reset`,
                //     headers: {
                //         'Content-Type': 'application/json',
                //     },
                //     data: { email: cu_email },
                // };
                // let INVITE: any;
                // const response = await axios.request(configE);
                // if (response.data.message == 'User with this email does not exist') {
                //     await client.service(SERVICES.USERS).create({
                //         name: customer_name,
                //         email: cu_email,
                //         password: newPassword,
                //         permissions: ['customer'],
                //         isTempUser: false
                //     })
                //     let customerFirstName = customer_name?.split(" ")?.shift();
                //     INVITE = template.body.replaceAll("[Customer Name]", customerFirstName || customer_name).replaceAll("[Link]", `${siteRootUrl}/login?email=${cu_email}`).replaceAll("[Email]", cu_email).replaceAll("[Password]", newPassword);
                // } else if (response?.data?.userId) {
                //     let customerFirstName = customer_name?.split(" ")?.shift();
                //     INVITE = template2.body.replaceAll("[Customer Name]", customerFirstName || customer_name).replaceAll("[Link]", `${siteRootUrl}/login?email=${cu_email}`).replaceAll("[Email]", cu_email);
                // }
                // const emailObj = {
                //     to: cu_email,
                //     subject: response?.data?.message === 'User with this email does not exist' ? template.subject : template2.subject,
                //     text: INVITE,
                // }
                // const email = await client.service(SERVICES.SENDEMAIL).create(emailObj)
                // toast.success('Email sent successfully', {
                //     position: toast.POSITION.BOTTOM_CENTER,
                // })
                toast.success('Job successfully created!', {
                    position: toast.POSITION.BOTTOM_CENTER,
                })
                window.location.href = "/customer"
            } else {
                window.location.href = "/login"
            }
        } else {
            toast.error(' Please fill in all required fields.', {
                position: toast.POSITION.BOTTOM_CENTER,
            })
        }
    }
    return (
        <div style={{ width: '100%' }}>
            <div
                style={{
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center,'
                }}
            >
                <div className="main_card_popup">
                    <div className="header_new">
                        <div className="card_top_heading"
                            style={{
                                padding: '20px 40px 0px 40px'
                            }}
                        >
                            {/* <h1 style={{color:'#00314D'}}>Requirements</h1> */}
                        </div>
                    </div>
                    <form onSubmit={onSubmit} >
                        <div className="main_section_card"
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 8,
                                margin: '20px 40px 40px',
                                cursor: 'pointer',
                                height: "100%",
                                overflow: "auto"

                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    lineHeight: '30px',
                                }}
                            >
                                <div style={{ fontWeight: '600', color: '#00314D' }}>App title</div>
                                <Input
                                    placeholder="Type app title.."
                                    width={'80%'}
                                    style={{ height: '40px' }}
                                    type="text"
                                    name='appTitle'
                                    value={predefinedForm.appTitle.value}
                                    className={`${predefinedForm.appTitle.touched && predefinedForm.appTitle.hasError ? "error" : ""}`}
                                    onChange={e => {
                                        onInputChange("appTitle", e.target.value, dispatchForm, predefinedForm);
                                    }}
                                    onBlur={e => {
                                        onFocusOut("appTitle", e.target.value, dispatchForm, predefinedForm);
                                    }}
                                />
                                {predefinedForm?.appTitle?.touched && predefinedForm?.appTitle?.hasError && (
                                    <div className="error">{predefinedForm?.appTitle?.error}</div>
                                )}
                            </div>
                        </div>
                        <div className="footer_new">
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: "center"
                                }}
                            >
                                <button
                                    type='submit'
                                    className='button new'
                                    style={{ background: '#2CBBE8' }}
                                >
                                    Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div >
    )
}
export default PredefinedPage;