import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import client from 'feathers-client'
import { Formik, FormikHelpers } from 'formik'
import { LINKS } from 'routes/constants'
import { generateRandomPassword, SERVICES, URL_PATH } from 'utils/constants'
import protonLogo from 'assets/Proton-white.svg'
import EmailSent from 'components/ActionModal/components/EmailSent'
import Button from 'components/forms/Button/Button'
import TextField from 'components/forms/TextField/TextField'
import SignInGoogle from 'components/forms/Button/SignInGoogle/SignInGoogle'
import Header from "pages/customer/Header";
import './contact.sass';
import { AuthContext } from 'context/AuthContext'
import axios, { AxiosRequestConfig } from 'axios';
import emailSentGraphic from 'assets/proton_email_send.png'

interface ICreateJobFormData {
    customer_name: string
    customer_email: string
}

interface ICreateJobForm { }

export const submitCreateJob = async (
    values: ICreateJobFormData,
    actions: FormikHelpers<ICreateJobFormData>,
    auth: any
) => {
    try {
        const newPassword: string = generateRandomPassword(10);

        const config: AxiosRequestConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${URL_PATH.CLIENT1}createUser`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                name: values.customer_name,
                email: values.customer_email,
                password: newPassword
            }),
        };

        const jobCreate: any = await axios.request(config);


        if (jobCreate.data.status === 200) {
            const configE: AxiosRequestConfig = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${URL_PATH.CLIENT1}GetEmailTemplate`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    "type": "customer_access"
                }),
            };
            const siteRootUrl: string = window.location.origin;
            const template = await axios.request(configE);

            if (template?.data?.template?.data?.length > 0) {
                const tempStr = template?.data?.template?.data[0]
                let customerFirstName = values.customer_name?.split(" ")?.shift();
                tempStr.body = tempStr.body.replaceAll("[Customer Name]", customerFirstName).replaceAll("[Link]", `${siteRootUrl}/login?email=${values.customer_email}`).replaceAll("[Email]", values.customer_email).replaceAll("[Password]", newPassword);


                const emailObj = {
                    to: values.customer_email, // Replace with your email address
                    subject: tempStr.subject,
                    text: tempStr.body,
                }

                const email = await client.service(SERVICES.SENDEMAIL).create(emailObj)

            }
            // actions.setSubmitting(false)
            // toast.success(``, {
            //     position: toast.POSITION.BOTTOM_CENTER,
            // })
            return true;
        } else {
            toast.error(jobCreate.data.message, {
                position: toast.POSITION.BOTTOM_CENTER,
            })
            return false
        }


    } catch (error: any) {
        toast.error(error.message, {
            position: toast.POSITION.BOTTOM_CENTER,
        })
        actions.setSubmitting(false)
        return false
    }
}

const google = 
<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.66391 0.723606C5.46577 1.48616 3.5701 2.93352 2.25533 4.85308C0.940572 6.77264 0.276022 9.06323 0.359297 11.3884C0.442572 13.7136 1.26928 15.9507 2.718 17.7713C4.16671 19.5919 6.16107 20.8999 8.40813 21.5033C10.2299 21.9734 12.1385 21.994 13.97 21.5634C15.6291 21.1908 17.1631 20.3936 18.4216 19.25C19.7314 18.0234 20.6821 16.463 21.1716 14.7366C21.7036 12.8591 21.7982 10.8847 21.4483 8.96501H11.2183V13.2086H17.1428C17.0244 13.8854 16.7707 14.5314 16.3968 15.1079C16.0229 15.6844 15.5366 16.1795 14.9669 16.5636C14.2434 17.0422 13.4279 17.3642 12.5727 17.5089C11.7149 17.6684 10.8351 17.6684 9.97735 17.5089C9.10799 17.3292 8.28558 16.9704 7.56251 16.4553C6.40089 15.6331 5.52867 14.4649 5.07032 13.1175C4.60422 11.7449 4.60422 10.2568 5.07032 8.88423C5.39658 7.9221 5.93594 7.04609 6.64813 6.32157C7.46315 5.47723 8.49498 4.87369 9.63043 4.57717C10.7659 4.28065 11.9611 4.30261 13.0848 4.64064C13.9627 4.91012 14.7655 5.38097 15.4292 6.01564C16.0972 5.35105 16.7641 4.68475 17.4299 4.01673C17.7736 3.65751 18.1483 3.31548 18.4869 2.94767C17.4738 2.00489 16.2846 1.2713 14.9875 0.788918C12.6254 -0.0687555 10.0409 -0.0918046 7.66391 0.723606Z" fill="white"/>
<path d="M7.6639 0.723587C10.0407 -0.092378 12.6252 -0.0699356 14.9875 0.78718C16.2848 1.27284 17.4735 2.00996 18.4851 2.95624C18.1414 3.32406 17.7787 3.66781 17.4281 4.02531C16.7612 4.69103 16.0949 5.35447 15.4292 6.01562C14.7655 5.38095 13.9627 4.9101 13.0848 4.64062C11.9614 4.30141 10.7663 4.27818 9.63052 4.57349C8.49478 4.86879 7.46231 5.47122 6.6464 6.31468C5.9342 7.03919 5.39485 7.91521 5.06858 8.87734L1.50562 6.11874C2.78094 3.58971 4.98909 1.65519 7.6639 0.723587Z" fill="#E33629"/>
<path d="M0.560319 8.85156C0.751824 7.90245 1.06976 6.98332 1.50563 6.11874L5.0686 8.88421C4.6025 10.2568 4.6025 11.7449 5.0686 13.1175C3.88152 14.0342 2.69386 14.9554 1.50563 15.8812C0.414484 13.7093 0.0817028 11.2346 0.560319 8.85156Z" fill="#F8BD00"/>
<path d="M11.2183 8.96329H21.4483C21.7982 10.883 21.7036 12.8574 21.1716 14.7349C20.6821 16.4613 19.7314 18.0217 18.4216 19.2483C17.2717 18.3511 16.1167 17.4608 14.9669 16.5636C15.537 16.1791 16.0235 15.6834 16.3974 15.1063C16.7713 14.5292 17.0249 13.8826 17.1428 13.2052H11.2183C11.2166 11.7924 11.2183 10.3778 11.2183 8.96329Z" fill="#587DBD"/>
<path d="M1.50391 15.8812C2.69214 14.9646 3.87979 14.0433 5.06687 13.1175C5.52614 14.4653 6.3996 15.6336 7.5625 16.4553C8.28783 16.9679 9.11201 17.3238 9.9825 17.5003C10.8403 17.6598 11.7201 17.6598 12.5778 17.5003C13.4331 17.3555 14.2486 17.0335 14.972 16.555C16.1219 17.4522 17.2769 18.3425 18.4267 19.2397C17.1684 20.3839 15.6345 21.1817 13.9752 21.5548C12.1437 21.9854 10.235 21.9647 8.41328 21.4947C6.97246 21.11 5.62663 20.4318 4.46016 19.5026C3.22551 18.5224 2.21711 17.2871 1.50391 15.8812Z" fill="#319F43"/>
</svg>;

const InviteSuccess = ({ email }: { email: string }) => {
    return (
        <div className="invite-success">
            {/* <EmailSent email={email} /> */}
            <div className="email-sent">
                <img
                    src={emailSentGraphic}
                    alt="email sent"
                    width={'250px'}
                    height={'300px'}
                />
                {/* <div className="title">Email sent!</div> */}
                <p>
                    Email was successfully sent to
                    <span className="email-address">{email}</span>
                </p>
            </div>
            {/* <Link to={LINKS.JOBS}>
                <Button>Go to Jobs</Button>
            </Link> */}
        </div>
    )
}

const FormikForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    isSuccess,
    emailTemplate
}: any) => {
    const navigate = useNavigate()
    const buildEmail = useMemo(() => {
        let template = emailTemplate;
        Object.keys(values).forEach((key) => {

            if (values[key]) {
                if (key === "customer_name") {
                    let firstName = values[key].split(" ").shift()
                    template = template.replaceAll(`[Customer Name]`, firstName || values[key])
                }
                template = template.replaceAll(`[${key}]`, values[key])
            }
        })
        return template
    }, [values, emailTemplate])


    return (

        <>
            <div className="login">
            <img className='backgroundLogin' src="/assets/backgroundlogin.png" />
            <div className="loginWrapper">
            <div className="login-right">
                    <div className="login-container">
                        <div className="login-content">
                            <div className="login-logo">
                                <img src="/assets/Protonlogo.png" alt="Proton Logo" />
                            </div>
                            <div className="create- -form-container " >
                                {isSuccess ? (
                                    <InviteSuccess email={values.customer_email} />
                                ) : (
                                    <>
                                        <div className="form_header">
                                            <h3>Sign up</h3>
                                            
                                        </div>
                                        <form onSubmit={handleSubmit}>
                                            <div className="form_inner">
                                                <TextField
                                                    label="Full name"
                                                    id="customer_name-input"
                                                    placeholder="Enter your full name"
                                                    type="text"
                                                    name="customer_name"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.customer_name}
                                                    errors={errors}
                                                    />
                                                <TextField
                                                    label="Email address"
                                                    id="customer_email-input"
                                                    placeholder="Enter your email address"
                                                    type="text"
                                                    name="customer_email"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.customer_email}
                                                    errors={errors}
                                                    />
                                            </div>

                                            
                                                {/* <Button
                                                type="button"
                                                disabled={isSubmitting}
                                                theme="secondary"
                                                style={{
                                                    marginRight: '12px',
                                                }}
                                                onClick={() => navigate(-1)}
                                            >
                                                Cancel
                                            </Button> */}
                                                
                                                <Button  className='googlebtn' type="submit" disabled={isSubmitting} style={{ width: "100%" }}>
                                                Sign up</Button>
                                                <Button
                                                type="submit"
                                                fullWidth
                                                className='googlebtn'
                                                >
                                                {google}
                                                Sign up with Google
                                                <SignInGoogle />
                                            </Button>
                                            <p className='sinupLabel'>Already have an account? <Link to='/login' style={{}}>Log in</Link></p>
                                           
                                        </form>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="login-left">
                    <img src="/assets/signUpback.png"></img>
                </div>
                
            </div>                            
            </div>

            {/* <Header /> */}
        </>
    )
}

const StartPage = ({ ...props }: ICreateJobForm) => {
    const auth = useContext(AuthContext)
    const handleSubmit = useCallback(submitCreateJob, [auth])
    const [emailSent, setEmailSent] = useState<boolean>(false)
    const [template, setTemplate] = useState<string>("")

    const navigate = useNavigate();

    const onSubmit = useCallback(
        async (
            values: ICreateJobFormData,
            actions: FormikHelpers<ICreateJobFormData>
        ) => {
            const form_res = await handleSubmit(values, actions, auth)
            form_res !== false ? setEmailSent(true) : setEmailSent(false)
        },
        [handleSubmit, auth]
    )

    useEffect(() => {

        const fetchTemplateTimer = setTimeout(async () => {
            const configE: AxiosRequestConfig = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${URL_PATH.CLIENT1}GetEmailTemplate`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    "type": "customer_access"
                }),
            };


            try {
                const templateResult = await axios.request(configE);
                if (templateResult.data.status === 200) {
                    setTemplate(templateResult.data.template.data[0].body)
                } else {
                    console.log("Template not found !!!")
                }
            }
            catch (err: any) {
                console.log("Something went wrong!!!", err.message)
            }
        }, 200)

        return () => clearTimeout(fetchTemplateTimer)
    }, [])

    useEffect(() => {

        if (auth !== null && auth.authentication && auth.user) {
            if (auth?.user?.permissions?.includes("temp") || auth?.user?.permissions?.includes("customer")) {
                navigate('/customer')
            } else {
                navigate('/jobs')
            }
        }
    }, [auth, navigate])

    const isValidEmail = (email: string) => {
        // Regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(email)
    }


    return (
        <Formik
            initialValues={{
                // name: '',
                customer_name: '',
                customer_email: '',
                // business_name: '',
            }}
            validate={(values: ICreateJobFormData) => {
                const errors: any = {}
                // if (!values.name) {
                //   errors.name = 'Required'
                // }

                if (!values.customer_name) {
                    errors.customer_name = 'Required'
                }

                if (!values.customer_email) {
                    errors.customer_email = 'Required'
                } else if (!isValidEmail(values.customer_email)) {
                    errors.customer_email = 'Invalid email'
                }

                // if (!values.business_name) {
                //   errors.business_name = 'Required'
                // }

                return errors
            }}
            onSubmit={onSubmit}
        >
            {({ ...props }) => <FormikForm {...props} isSuccess={emailSent} emailTemplate={template} />}
        </Formik>
    )
}

export default StartPage;
