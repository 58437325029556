import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './index.sass';
import { getPAYMENT_EditCardLink } from 'routes/constants';
import TickImage from 'assets/tick.png';

const HowToBuildpage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  console.log('location', location);

  // Separate states for each button
  const [isConfirmingDeposit, setIsConfirmingDeposit] = useState(false);
  const [isConfirmingQuote, setIsConfirmingQuote] = useState(false);
  const [quoteDate, setQuoteDate] = useState('');

  // Example deposit amount
  const deposit = 100;

  const handlePayClick = () => {
    if (!isConfirmingDeposit) {
      setIsConfirmingDeposit(true);
    } else {
      alert('Processing deposit payment...');
      setIsConfirmingDeposit(false);
    }
  };

  const handleQuoteClick = () => {
    if (!isConfirmingQuote) {
      const currentDate = new Date().toLocaleDateString(); // This will get the current date
      setQuoteDate(currentDate); // Set the date in the state
      setIsConfirmingQuote(true);
    } else {
      alert('Requesting quote...');
      setIsConfirmingQuote(false);
    }
  };

  const handlePaymentRedirect = () => {
    // Navigate to the payment page if payment is not received
    navigate('/payments');
  };

  const finalPaymentReceived = location?.state?.jobData?.finalPaymentReceived;
  const appType = location?.state?.jobData?.appIndustry;

  return (
    <>
      <div className='appContainer'>
        {appType === "Custom" ? (
          // CUSTOM APP CODE START HERE
          <div className='custom'>
            <div className='wrapperBuildApp'>
              <h6 className='titleBuild'>How to build test new app #{location?.state?.jobData?.id}</h6>
              <p className='paraApp'>AppType: {appType}</p>
            </div>
            <div className='infomationNotesWrapper'>
              <p className='notePara'>Please click Request Quote to get a price to build this app.</p>

              <button
                style={{
                  color: 'white',
                  border: 'none',
                  cursor: 'pointer',
                  backgroundColor: isConfirmingQuote ? '#136f13e6' : '#00B8D9',
                  transition: 'background-color 0.3s ease, transform 0.2s ease',
                  boxShadow: isConfirmingQuote ? '0px 4px 15px rgba(0, 0, 0, 0.2)' : '0px 2px 10px rgba(0, 0, 0, 0.1)'
                }}
                onClick={handleQuoteClick}
                className={`pay-button ${isConfirmingQuote ? 'confirm' : ''}`}
              >
                {isConfirmingQuote ? quoteDate : 'Request Quote'}
              </button>
            </div>
          </div>
        ) : (
          // PREDEFINED APP CODE START HERE
          <div className='Predefined'>
            <div className='wrapperBuildApp'>
              <h6 className='titleBuild'>How to build test new app #{location?.state?.jobData?.id}</h6>
              <p className='paraApp'>AppType: {appType}</p>
            </div>
            <div className='infomationNotesWrapper'>
              <p className='notePara'>
                When the deposit has been paid we will automatically start working on your app.
              </p>

              <ul className='ListPoints'>
                <li>A Project Manager will be assigned</li>
                <li>Your app will be built for Apple and Google phones</li>
                <li>You will receive a one month free Proton subscription</li>
                <li>The price shown here is fixed and will not increase</li>
              </ul>

              <div className='depositWrapper'>
                <div className='depositeRow'>
                  <div className='depositeColumn'><b>Deposit</b></div>
                  <div className='depositeColumn'><b>${location?.state?.jobData?.deposit}</b></div>
                </div>
                <div className='depositeRow'>
                  <div className='depositeColumn'>Final Payment</div>
                  <div className='depositeColumn'>${location?.state?.jobData?.finalPayment}</div>
                </div>
                <div className='depositeRow depositeRowTotal'>
                  <div className='depositeColumn'>Total</div>
                  <div className='depositeColumn'>${location?.state?.jobData?.finalPayment}</div>
                </div>
              </div>

              <>
                {finalPaymentReceived ? (
                  // If payment is received, show the "ticked" button
                  <a href='#' style={{ backgroundColor: '#136f13e6' }} className='button primary medium-btn setupPay'>
                    <img src={TickImage} alt='Tick' />
                    Payment Received
                  </a>
                ) : (
                  // If payment is not received, redirect to the payment page
                  <button
                    style={{ backgroundColor: '#00B8D9', color: 'white', border: 'none', cursor: 'pointer' }}
                    onClick={handlePaymentRedirect}
                    className='button primary medium-btn setupPay'
                  >
                    Setup Payment Method
                  </button>
                )}
              </>

              <button
                style={{
                  color: 'white',
                  border: 'none',
                  cursor: 'pointer',
                  backgroundColor: isConfirmingDeposit ? '#136f13e6' : '#00B8D9',
                  transition: 'background-color 0.3s ease, transform 0.2s ease',
                  boxShadow: isConfirmingDeposit ? '0px 4px 15px rgba(0, 0, 0, 0.2)' : '0px 2px 10px rgba(0, 0, 0, 0.1)'
                }}
                onClick={handlePayClick}
                className={`pay-button ${isConfirmingDeposit ? 'confirm' : ''}`}
              >
                {isConfirmingDeposit ? 'Confirm' : `Pay Deposit $3444`}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default HowToBuildpage;
