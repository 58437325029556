import './email-preview.sass'

interface IEmailPreviewProps {
  body: string
}

const EmailPreview = ({ body }: IEmailPreviewProps) => {
  const isHTML = /<\/?[a-z][\s\S]*>/i.test(body)  // Simple regex to detect HTML tags

  return (
    <div className="email-preview">
      <div className="preview-title">Email preview</div>
      {isHTML ? (
        <div className="preview-contents" dangerouslySetInnerHTML={{ __html: body }} />
      ) : (
        <pre className="preview-content">{body}</pre>
      )}
    </div>
  )
}

export default EmailPreview


// import './email-preview.sass'

// interface IEmailPreviewProps {
//   body: string
// }

// const EmailPreview = ({ body }: IEmailPreviewProps) => {
//   return (
//     <div className="email-preview">
//       <div className="preview-title">Email preview</div>
//       <pre className="preview-content">{body}</pre>
//     </div>
//   )
// }

// export default EmailPreview
