import React, { useContext } from 'react';
import { GoogleLogin, GoogleOAuthProvider, googleLogout } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import gmail from 'assets/gmail.svg';
import './sign-in-google.sass';
import { AuthContext } from 'context/AuthContext';
import { google_client_id ,URL_PATH} from '../../../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import client from 'feathers-client';

interface DecodedData {
  email: string;
  sub: any;
}

const SignInGoogle = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const userCreatedHandler = async (credentialResponse: { credential: string }) => {
    try {
      const decoded: DecodedData = jwtDecode(credentialResponse.credential);

      if (decoded && decoded.sub) {
        const baseUrl = URL_PATH.CLIENT1;
        const googleAuthEndpoint = `${baseUrl}authentication/google`;
        await axios.post(googleAuthEndpoint, {
          strategy: 'google',
          email: decoded.email,
          profile: decoded
        })
          .then(async (responce) => {
            await client.authenticate({
              strategy: 'google',
              email: decoded.email,
              profile: decoded
            });
            navigate('/jobs');
          })
          .catch(async (error: any) => {
            if (error?.response?.data?.message == "User with the same email already exist.") {
              await client.authenticate({
                strategy: 'google',
                email: decoded.email,
                profile: decoded
              });
              navigate('/jobs');
            } else {
              toast.error(`User not found`, {
                position: toast.POSITION.BOTTOM_CENTER,
              })
            }
          });
      } else {
        console.error("The 'sub' property is missing in the decoded object");
      }
    } catch (error: any) {
      console.log(">>>>>>>>>>", error);

      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  return (
    <div className="sign-in-google google-icon-custom">
      <GoogleOAuthProvider clientId={google_client_id} >
        <GoogleLogin
          type="standard"
          size="large"
          text="continue_with"
          logo_alignment="center"
          width="500"
          shape="square"
          max-width="500"
          onSuccess={(credentialResponse: any) => {
            userCreatedHandler(credentialResponse);
          }}
          onError={() => {
            console.log('Login Failed');
          }}
        />
      </GoogleOAuthProvider>
    </div>
  );
};

export default SignInGoogle;
