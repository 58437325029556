import { HTMLAttributes, useState, useEffect, useContext, useMemo } from 'react'
import client from 'feathers-client'
import { SERVICES } from 'utils/constants'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import Avatar from 'components/Avatar/Avatar'
import './user.sass'
import { Select } from 'antd'
import { AuthContext } from 'context/AuthContext'
import classNames from 'classnames'
import KebabMenu from 'components/KebabMenu/KebabMenu'
import DeleteModal from 'components/ActionModal/DeleteModal'
import deleteIcon from 'assets/delete.svg'
import planeIcon from 'assets/paper-plane 1.svg'
import RoleBadge from 'components/RoleBadge/RoleBadge'

interface IStaffItemProps extends HTMLAttributes<HTMLDivElement> {
  staff?: any
  showAdded?: boolean
  showInvited?: boolean
  handleDeleteUser?: any
  handleResendEmail?: any
  template?: any
}

const UserItem = ({
  // @ts-ignore
  staff = any,
  showAdded = false,
  showInvited = false,
  handleDeleteUser,
  handleResendEmail = () => { },
  template,
  ...props
}: IStaffItemProps) => {
  const auth = useContext(AuthContext)
  const [job_is_temper, setjob_is_temper] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false)
  const Option = Select.Option
  const user_id = staff._id;
  const handleSort = async (e: any) => {
    const userStatus = e === 'active' ? false : true;
    const userPermission = e === 'active' ? ['customer'] : ['temp'];
    if (auth) {
      try {
        const userDataStatus: any = await client.service(SERVICES.USERS).patch(user_id, {
          isTempUser: userStatus,
          permissions: userPermission,
        });
        const userStatusData: boolean = (userDataStatus?.isTempUser as boolean) || false;
        setjob_is_temper(userStatusData)

        toast.success('User status updated successfully', {
          position: toast.POSITION.BOTTOM_CENTER,
        })
      } catch (error: any) {
        toast.error('User status update failed', {
          position: toast.POSITION.BOTTOM_CENTER,
        })
      }
    }
  }

  const fetchJob_is_temper = async () => {
    if (auth) {
      try {
        const userDataStatus: any = await client.service(SERVICES.USERS).get(user_id);
        const userStatusData: boolean = (userDataStatus?.isTempUser as boolean) || false;
        setjob_is_temper(userStatusData)
      } catch (error: any) {
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    }
  }

  const kebabMenuItems = useMemo(
    () => [
      {
        label: 'Resend',
        icon: planeIcon,
        onClick: () => {
          handleResendEmail(staff)
        },
      },
      {
        label: 'Delete',
        icon: deleteIcon,
        onClick: () => {
          setDeleteOpen(true)
        },
      },
    ],
    [staff, template]
  )

  const handleDelete = async () => {
    try {
      await handleDeleteUser(staff._id); // Call parent handleDeleteUser with staff id

      // Close the modal after successful deletion
      setDeleteOpen(false);
    } catch (error) {
      toast.error('Failed to delete user', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  useEffect(() => {
    fetchJob_is_temper()
  }, [auth])


  return (
    <div className="staff-item" {...props}>
      <div className="left">
        <Avatar size={56} name={staff.name} />
        <div className="details">
          <div className="name">{staff.name}</div>
          <div className="bottom">
            <span className='email'>{staff.email}</span>
            <span className='spacer' style={{ display: 'block', width: '1px', background: '#B7C5CC', height: '20px' }}></span>
            <div
              className={classNames('role-badge progress-badge', {
                teal: staff.permissions[0] === 'administrator',
              })}
            >
              {'Customer'}
            </div>


          </div>
          <div className="bottom">
            {showAdded && (
              <>
                <span className='date'>
                  added on {dayjs(staff.added).format('MMM DD, YYYY h:mm A')}
                </span>
              </>
            )}
            {showInvited && (
              <>
                <span className='spacer' style={{ display: 'block', width: '1px', background: '#B7C5CC', height: '20px' }}></span>
                <span className='date'>
                  invited on{' '}
                  {dayjs(staff.invited).format('MMM DD, YYYY h:mm A')}
                </span>
              </>
            )}
          </div>
        </div>
      </div>
      <div className='right'>
        {/* <div className="sort-title">Status:</div> */}

        {/* <Select value={job_is_temper ? 'inactive' : 'active'}
          style={{ width: 180 }}
          onChange={(e) => handleSort(e)}
        >
          <Select.Option value="active">Active</Select.Option>
          <Select.Option value="inactive">Inactive</Select.Option>
        </Select> */}
        <KebabMenu items={kebabMenuItems} />
        <DeleteModal
          isOpen={deleteOpen}
          handleClose={() => {
            setDeleteOpen(false)
          }}
          body={<ModalPreviewStaffTemplate staff={staff} />}
          handleDelete={handleDelete}
          title="Delete user"
          subtitle="Do you wish to delete the user?"
        />
      </div>
    </div>
  )
}

const ModalPreviewStaffTemplate = ({ staff }: any) => {
  return (
    <div className="modalPrev">
      <Avatar size={56} name={staff.name} url={staff.avatar} />
      <div className="details">
        <div className="name">{staff.name}</div>
        <div className="bottom">
          <RoleBadge role={staff.role} />
          <span className="mid-dot">&middot;</span>
          <span>{staff.email}</span>
        </div>
      </div>
    </div>
  )
}

export default UserItem
